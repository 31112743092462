/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
// import Swiper JS
import(/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ 'root/utils/swiper.js');
import(/* webpackPrefetch: true */ '../utils/header.js');
//Load fontfacek
import(/* webpackChunkName: "fontfaceobserver" */ 'fontfaceobserver').then((module) => {
  const FontFaceObserver = module.default;

  const fontSFProDisplayBlackItalic = new FontFaceObserver('SFProDisplay-BlackItalic');
  const fontSFProDisplayBold = new FontFaceObserver('SFProDisplay-Bold');
  const fontSFProDisplayLightItalic = new FontFaceObserver('SFProDisplay-LightItalic');
  const fontSFProDisplayMedium = new FontFaceObserver('SFProDisplay-Medium');
  const fontSFProDisplayRegular = new FontFaceObserver('SFProDisplay-Regular');
  const fontSFProDisplaySemibold = new FontFaceObserver('SFProDisplay-Semibold');
  const fontSFProDisplayItalic = new FontFaceObserver('SFProDisplay-Italic');
  const fontSFProDisplayUltralight = new FontFaceObserver('SFProDisplay-Ultralight');
  const fontSVNGrandSlang = new FontFaceObserver('SVNGrandSlang');

  Promise.all([
    fontSFProDisplayBlackItalic.load(),
    fontSFProDisplaySemibold.load(),
    fontSFProDisplayUltralight.load(),
    fontSFProDisplayRegular.load(),
    fontSFProDisplayItalic.load(),
    fontSFProDisplayBold.load(),
    fontSFProDisplayLightItalic.load(),
    fontSFProDisplayMedium.load(),
    fontSVNGrandSlang.load(),
  ]).then(function () {
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif',
      'SFProDisplay-Regular, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-lightItalic',
      'SFProDisplay-LightItalic, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-italic',
      'SFProDisplay-Italic, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-bold',
      'SFProDisplay-Bold, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-medium',
      'SFProDisplay-Medium, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif',
      'SFProDisplay-BlackItalic, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-semibold',
      'SFProDisplay-Semibold, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-ultralight',
      'SFProDisplay-Ultralight, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-grandslang',
      'SVNGrandSlang, sans-serif'
    );
  });
});
import $ from 'jquery';
import slick from 'slick-carousel';
import { Collapse, Modal } from 'bootstrap';



if (document.body.contains(document.getElementById('close_button'))) {
  document.getElementById('close_button').addEventListener('click', (event) => {
    // var expires = new Date().getTime() + 31536000000;
    // document.cookie = 'head_widget=clicked;expires=' + expires + ';path=/';
    //localStorage.setItem('head_widget', 'clicked');
    //document.querySelector('.head_widget').classList.add('d-none');
    document.querySelector('main').classList.add('show');
  });
}

window.addEventListener('DOMContentLoaded', function (event) {
  let checkStorage = localStorage.getItem('head_widget');
  if (checkStorage) {
    document.querySelector('main').classList.add('show');
  }
});

// document.getElementById('icon_quote').addEventListener('click', (event) => {
//   document.querySelector('.mini-quote-dropdown').classList.add('show');
// });

// document.getElementById('close_cart').addEventListener('click', (event) => {
//   document.querySelector('.mini-quote-dropdown').classList.remove('show');
// });

submitCustomFilterProduct('filter_name');
submitCustomFilterProduct('filter_color');

function submitCustomFilterProduct(selector) {
  const selectorElm = document.getElementById(selector);

  if (selectorElm !== null) {
    document.getElementById(selector).addEventListener('change', () => {
      document.getElementById('custom_filter_product').submit();
    });
  }
}

window.addEventListener('DOMContentLoaded', function () {
  const buttonsleft = document.querySelectorAll('.block-left .module-img .column_item');

  buttonsleft.forEach((button) => {
    const content = button.querySelector(':scope > img');
    content.style.minHeight = content.scrollHeight + 'px';
    button.addEventListener('click', function () {
      buttonsleft.forEach((buttonClick) => {
        buttonClick.classList.remove('active');
        this.classList.add('active');
      });
    });
  });
  const buttonsright = document.querySelectorAll('.block-right .module-img .column_item');

  buttonsright.forEach((button) => {
    const content = button.querySelector(':scope > img');
    content.style.minHeight = content.scrollHeight + 'px';
    button.addEventListener('click', function () {
      buttonsright.forEach((buttonClick) => {
        buttonClick.classList.remove('active');
        this.classList.add('active');
      });
    });
  });
  if (document.body.contains(document.querySelector('.block-center'))) {
    var buttonshover = document.querySelectorAll('.block-center .column_item img:not(.hover-img)');
    var buttonsactive = document.querySelector('.block-center .active img.hover-img');

    buttonshover.forEach((item) => {
      item.addEventListener('mouseover', (e) => {
        buttonsactive.src = item.src;
        buttonsactive.classList.add('show');
      });
      item.addEventListener('mouseout', (e) => {
        buttonsactive.classList.remove('show');
      });
    });
    const eleactive = document.querySelector('.list_post_main .list_post_main .column_item.active');
    if (window.innerWidth < 992) {
      document
        .querySelector('.list_post_main .list_post_main')
        .insertAdjacentElement('afterbegin', eleactive);
    }
  }
});
if (document.body.contains(document.querySelector('.footer .newsletter'))) {
  window.addEventListener('DOMContentLoaded', function (event) {
    document.querySelector('.footer .newsletter .wpcf7-spinner').classList.add('d-none');

    const eleactive = document.querySelector('.footer .copy-right');
    if (window.innerWidth < 992) {
      document.querySelector('.footer .newsletter').insertAdjacentElement('afterend', eleactive);
    }
  });
}

$(document).ready(function () {
  var currentPath = window.location.pathname;

  $('.product-category-tabs-list a').each(function () {
    var linkPath = $(this).attr('slug');
    if (currentPath === linkPath) {
      $(this).addClass('active');
    }
  });

  $(document).on('click', '.quantity-plus', function () {
    var $input = $(this).prev('input');
    var val = parseInt($input.val());
    $input.val(val + 1).change();
  });

  $(document).on('click', '.quantity-minus', function () {
    var $input = $(this).next('input');
    var val = parseInt($input.val());
    if (val > 1) {
      $input.val(val - 1).change();
    }
  });

  $('.slider-catalog').slick({
    slidesToShow: 3,
    loop: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: function(slider, i) {
      // Limit the number of dots to, for example, 5
      if (i < 5) {
          return '<button>' + (i + 1) + '</button>';
      } else {
          return false;
      }
  },
  });
  
  $('.product-related').slick({
    slidesToShow: 4,
    loop: true,
    centerMode: true,
    prevArrow: '.swiper-button-prev', // Slick syntax for prev button
    nextArrow: '.swiper-button-next', // Slick syntax for next button
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: function(slider, i) {
      // Limit the number of dots to, for example, 5
      if (i < 5) {
          return '<button>' + (i + 1) + '</button>';
      } else {
          return false;
      }
  },
  });
  $('.product-new').slick({
    slidesToShow: 4,
    loop: true,
    centerMode: true,
    prevArrow: '.swiper-button-prev', // Slick syntax for prev button
    nextArrow: '.swiper-button-next', // Slick syntax for next button
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: function(slider, i) {
      // Limit the number of dots to, for example, 5
      if (i < 5) {
          return '<button>' + (i + 1) + '</button>';
      } else {
          return false;
      }
  },
  });
  $('.product-brand').slick({
    slidesToShow: 4,
    loop: true,
    centerMode: true,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  //   customPaging: function(slider, i) {
  //     // Limit the number of dots to, for example, 5
  //     if (i < 5) {
  //         return '<button>' + (i + 1) + '</button>';
  //     } else {
  //         return false;
  //     }
  // },
  });
  $('.related-post').slick({
    slidesToShow: 3,
    loop: true,
    centerMode: true,
    prevArrow: '.swiper-button-prev', // Slick syntax for prev button
    nextArrow: '.swiper-button-next', // Slick syntax for next button
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: function(slider, i) {
      // Limit the number of dots to, for example, 5
      if (i < 5) {
          return '<button>' + (i + 1) + '</button>';
      } else {
          return false;
      }
  },
  });

  $('.related-case').slick({
    slidesToShow: 3,
    loop: true,
    // centerMode: true,
    prevArrow: '.swiper-button-prev', // Slick syntax for prev button
    nextArrow: '.swiper-button-next', // Slick syntax for next button
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: function(slider, i) {
      // Limit the number of dots to, for example, 5
      if (i < 5) {
          return '<button>' + (i + 1) + '</button>';
      } else {
          return false;
      }
  },
  });

  
  $(".category-tabs li a").on("click", function (e) {
    e.preventDefault();
  
    // Remove the active class from all tabs
    $(".category-tabs li a").removeClass("active");
  
    // Add the active class to the clicked tab
    $(this).addClass("active");
  
    var categoryId = $(this).data("category-id");
    var ajaxUrl = ajax_object.ajax_url;
  
    // Check if the clicked category is "All"
    if (categoryId === "0") {
      // If "All" is clicked, load all products
      $.ajax({
        type: "POST",
        url: ajaxUrl,
        data: {
          action: "load_all_products",
          category_id: categoryId
        },
        success: function (response) {
          $("#ajax-product-container").html(response);
  
          $('.product-related').slick({
            slidesToShow: 4,
            loop: true,
            centerMode: true,
            prevArrow: '.swiper-button-prev', // Slick syntax for prev button
            nextArrow: '.swiper-button-next', // Slick syntax for next button
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ],
            customPaging: function(slider, i) {
              // Limit the number of dots to, for example, 5
              if (i < 5) {
                  return '<button>' + (i + 1) + '</button>';
              } else {
                  return false;
              }
          },
          });
  
        },
      });
    } else {
      // If a specific category is clicked, load related products
      $.ajax({
        type: "POST",
        url: ajaxUrl,
        data: {
          action: "load_related_products",
          category_id: categoryId,
        },
        success: function (response) {
          $("#ajax-product-container").html(response);
  
          $('.product-related').slick({
            slidesToShow: 4,
            loop: true,
            centerMode: true,
            prevArrow: '.swiper-button-prev', // Slick syntax for prev button
            nextArrow: '.swiper-button-next', // Slick syntax for next button
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ],
            customPaging: function(slider, i) {
              // Limit the number of dots to, for example, 5
              if (i < 5) {
                  return '<button>' + (i + 1) + '</button>';
              } else {
                  return false;
              }
          },
          });
  
        },
      });
    }
  });

  $(".news-category-tabs li a").on("click", function (e) {
    e.preventDefault();

    $(".news-category-tabs li a").removeClass("active");
    $(this).addClass("active");

    var categoryId = $(this).data("news-category-id");
    var ajaxUrl = ajax_object.ajax_url;

    if (categoryId === "0") {
        $.ajax({
            type: "POST",
            url: ajaxUrl,
            data: {
                action: "load_latest_products",
                category_id: categoryId
            },
            success: function (response) {
                $("#ajax-news-product-container").html(response);
                initSlick(); // Initialize Slick after content is loaded
            },
        });
    } else {
        $.ajax({
            type: "POST",
            url: ajaxUrl,
            data: {
                action: "load_latest_products",
                category_id: categoryId,
            },
            success: function (response) {
                $("#ajax-news-product-container").html(response);
                initSlick(); // Initialize Slick after content is loaded
            },
        });
    }

});

function initSlick() {
        $('.product-new').slick({
            slidesToShow: 4,
            loop: true,
            centerMode: true,
            prevArrow: '.swiper-button-prev',
            nextArrow: '.swiper-button-next',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                    },
                },
            ],
            customPaging: function(slider, i) {
              // Limit the number of dots to, for example, 5
              if (i < 5) {
                  return '<button>' + (i + 1) + '</button>';
              } else {
                  return false;
              }
          },
        });
   
}
function initSlickBrand() {
  $('.product-brand').slick({
      slidesToShow: 4,
      loop: true,
      centerMode: true,
      dots:true,
      responsive: [
          {
              breakpoint: 992,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
              },
          },
      ],
      customPaging: function(slider, i) {
        // Limit the number of dots to, for example, 5
        if (i < 5) {
            return '<button>' + (i + 1) + '</button>';
        } else {
            return false;
        }
    },
  });

}


  // Function to trigger swiper-button-next click
  function triggerNext() {
    $('.swiper-button-next').click();
  }

  // Function to trigger swiper-button-prev click
  function triggerPrev() {
    $('.swiper-button-prev').click();
  }

  // Click event for button-next
  $('.button-next').on('click', function () {
    triggerNext();
  });

  // Click event for button-prev
  $('.button-prev').on('click', function () {
    triggerPrev();
  });
  $(".button-contact .icon-submit").click(function(){
    console.log("Paragraph clicked"); // Check if the click event is triggered
    $(".wpcf7-submit").click();
  });
  
  $(".brand-category-tabs li a").on("click", function (e) {
    e.preventDefault();

    $(".brand-category-tabs li a").removeClass("active");
    $(this).addClass("active");

    var categoryId = $(this).data("brand-category-id");
    var ajaxUrl = ajax_object.ajax_url;

    if (categoryId === "0") {
        $.ajax({
            type: "POST",
            url: ajaxUrl,
            data: {
                action: "load_brand_products",
                category_id: categoryId
            },
            success: function (response) {
                $("#ajax-brand-product-container").html(response);
                initSlickBrand(); // Initialize Slick after content is loaded
            },
        });
    } else {
        $.ajax({
            type: "POST",
            url: ajaxUrl,
            data: {
                action: "load_brand_products",
                category_id: categoryId,
            },
            success: function (response) {
                $("#ajax-brand-product-container").html(response);
                initSlickBrand(); // Initialize Slick after content is loaded
            },
        });
    }
});





});

document.addEventListener('DOMContentLoaded', function () {
  var tabLinks = document.querySelectorAll('.tab-links a');
  var tabs = document.querySelectorAll('.tab');

  tabLinks.forEach(function (link) {
    link.addEventListener('click', function (e) {
      e.preventDefault();

      // Loại bỏ class "active" từ tất cả các tabs và thêm vào tab được chọn
      tabs.forEach(function (tab) {
        tab.classList.remove('active');
      });
      document.querySelector(this.getAttribute('href')).classList.add('active');

      // Loại bỏ class "active" từ tất cả các tab links và thêm vào tab link được chọn
      tabLinks.forEach(function (tabLink) {
        tabLink.classList.remove('active');
      });
      this.classList.add('active');
    });
  });
});

document.getElementById('play-button').addEventListener('click', function() {
  var videoContainer = document.getElementById('video-container');
  var video = document.getElementById('custom-video');
  videoContainer.style.backgroundImage = 'none'; // Hide background image
  video.style.display = 'block';
  video.play();
  this.style.display = 'none';
});

function checkAll() {
  var checkboxes = document.querySelectorAll('.item_cb');
  var selectAllCheckbox = document.getElementById('select-all-checkbox');
  var allChecked = true;

  for (var i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) {
          allChecked = false;
          break;
      }
  }

  selectAllCheckbox.checked = allChecked;
}
var checkboxes = document.querySelectorAll('.item_cb');

checkboxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', checkAll);
});